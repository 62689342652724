import Moralis from 'moralis';

import api, { authApi } from '../utils/api';
import { setAlert } from './alert';
import {
	LOAD_USER_PENDING,
	LOAD_USER_SUCCESS,
	LOAD_USER_FAILED,
	LOGIN,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_PENDING,
	LOGOUT_SUCCESS,
	LOGOUT_FAILED,
	CLEAR_PROFILE,
	UPDATE_PAYMENT_METHOD,
	UPDATE_PAYMENT_METHOD_SUCCESS,
	UPDATE_PAYMENT_METHOD_FAILED,
	SIGNUP_WITH_MM,
	SIGNUP_WITH_MM_SUCCESS,
	SIGNUP_WITH_MM_FAILED,
	CLEAR_ERROR,
	VALIDATE_MORALIS_SESSION_PENDING,
	VALIDATE_MORALIS_SESSION_SUCCESS,
	VALIDATE_MORALIS_SESSION_FAILED,
	CHECK_USER_EXISTS_PENDING,
	CHECK_USER_EXISTS_SUCCESS,
	CHECK_USER_EXISTS_FAILED
} from './types';
import { GetStreamUtil } from '../utils/getstream';
import { SocketUtil } from '../utils/socket';
import * as TokenUtil from '../utils/token';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// Load User
export const loadUser = () => async (dispatch) => {
	try {
		dispatch({
			type: LOAD_USER_PENDING
		})
		const res = await api.get('/auth');

		dispatch({
			type: LOAD_USER_SUCCESS,
			payload: res.data,
		});

		SocketUtil.getInstance();
	} catch (err) {
		dispatch({
			type: LOAD_USER_FAILED,
		});
	}
};

export const checkLiveUsername = async (name) => {
	try {
		let res = null
		const body = JSON.stringify({ name });
		res = await api.post('/auth/check_name', body);
		return res.data

	} catch (err) {
	}
	return { available: false }
};

// Login User
export const login = (payload) => async (dispatch) => {
	dispatch({
		type: LOGIN
	});
	try {
		TokenUtil.clear();
		const user = await Moralis.authenticate(payload);

		return user;
	} catch (err) {
		dispatch({
			type: LOGIN_FAIL,
			payload: {}
		});
		dispatch(setAlert(err?.message || 'Something went wrong', 'danger'))
	}
};

export const checkIfUserExists = (email, publicAddress, name) => async (dispatch) =>  {
	try {
		if (!email && !publicAddress && !name) return;
		dispatch({
			type: CHECK_USER_EXISTS_PENDING
		});
		let url = `/users?`;

		if (email) url += `email=${email}&`;
		if (publicAddress) url += `publicAddress=${publicAddress}&`;
		if (name) url += `name=${name}`;
		const { data } = await api.get(url);
		dispatch({
			type: CHECK_USER_EXISTS_SUCCESS
		});

		if (data?.user) {
			return true;
		}

		return false;
	} catch (err) {
		dispatch({
			type: CHECK_USER_EXISTS_FAILED
		});
		if (err?.response?.data?.message === 'User not found') {
			return false;
		}
	}
}

// Logout / Clear Profile
export const logout = () => async (dispatch) => {
	try {
		dispatch({ type: LOGOUT_PENDING });
		await Moralis.User.logOut()
		TokenUtil.clear();
		dispatch({ type: CLEAR_PROFILE });
		dispatch({ type: LOGOUT_SUCCESS });
	} catch (err) {
		dispatch({ type: LOGOUT_FAILED });
		dispatch(setAlert(err?.message || 'Something went wrong', 'danger'))
	}
};

export const updatePaymentMethod = (paymentMethodId) => async (dispatch) => {
	try {
		dispatch({
			type: UPDATE_PAYMENT_METHOD,
		});
		const resp = await api.post('payment/update-payment-method', {
			paymentMethodId,
		});

		dispatch({
			type: UPDATE_PAYMENT_METHOD_SUCCESS,
			payload: resp.data,
		});
	} catch (err) {
		dispatch({
			type: UPDATE_PAYMENT_METHOD_FAILED,
			payload: { msg: err.response.data.msg, status: err.response.status },
		});
	}
};

export const signUpWithMM = (name, publicAddress, email) => async (dispatch) => {
	try {
		dispatch({
			type: SIGNUP_WITH_MM
		});
		const { data } = await api.post('/users/metamask', { name, publicAddress, email });
		if (GetStreamUtil.getInstance().isUserSet) {
			await GetStreamUtil.getInstance().disconnect();
		}
		await GetStreamUtil.getInstance().setUser({
			id: data._id,
			name: data.name,
			image: data.avatar,
			gs_token: data.gs_token
		})
		
		api.post('/users/send-welcome-message', { user: data });
		dispatch({
			type: SIGNUP_WITH_MM_SUCCESS
		})
	} catch (err) {
		dispatch({
			type: SIGNUP_WITH_MM_FAILED
		});
	}
}

export const clearError = () => dispatch => {
	dispatch({
		type: CLEAR_ERROR
	})
}

export const validateMoralisSession = (sessionToken) => async (dispatch) => {
	try {
		dispatch({
			type: VALIDATE_MORALIS_SESSION_PENDING
		});
		const { data } = await authApi.post('/verify-session', { sessionToken });
		const { refreshToken, accessToken } = data;

		TokenUtil.setRefreshToken(refreshToken);
		TokenUtil.setAccessToken(accessToken);
		dispatch({
			type: VALIDATE_MORALIS_SESSION_SUCCESS
		});
		dispatch(loadUser());
	} catch (err) {
		dispatch({
			type: VALIDATE_MORALIS_SESSION_FAILED
		})
	}
}

